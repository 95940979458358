@import "~antd/es/style/themes/default.less";

@pro-header-hover-bg: rgba(0, 0, 0, 0.025);
@layout-header-padding: 0 0;

.action {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;

  > span {
    vertical-align: middle;
  }

  &:hover {
    //background: @pro-header-hover-bg;
    color: @primary-color;
  }

  &:global(.opened) {
    background: @pro-header-hover-bg;
    color: @primary-color;
  }
}

@primary-color: #0A66C2;@border-radius-base: 5px;