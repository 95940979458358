@import "~antd/es/style/themes/default.less";

.headerSearch {
  display: inline-flex;
  align-items: center;
  .input {
    width: 0;
    min-width: 0;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    transition: width 0.3s, margin-left 0.3s;
    :global(.ant-select-selection) {
      background: transparent;
    }
    input {
      box-shadow: none !important;
    }

    &.show {
      width: 210px;
      margin-left: 8px;
    }
  }
}

@primary-color: #0A66C2;@border-radius-base: 5px;