@import '~antd/es/style/themes/default.less';

.baseView {
  display: flex;
  padding-top: 12px;

  :global {
    .ant-legacy-form-item .ant-legacy-form-item-control-wrapper {
      width: 100%;
    }
  }

  .left {
    min-width: 224px;
    max-width: 448px;
  }

  .right {
    flex: 1;
    padding-left: 104px;

    .avatar_title {
      height: 22px;
      margin-bottom: 8px;
      color: @heading-color;
      font-size: @font-size-base;
      line-height: 22px;
    }

    .avatar {
      width: 144px;
      height: 144px;
      margin-bottom: 12px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .button_view {
      width: 144px;
      text-align: center;
    }
  }
}

.area_code {
  width: 72px;
}

.phone_number {
  width: 214px;
}

@media screen and (max-width: @screen-xl) {
  .baseView {
    flex-direction: column-reverse;

    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 448px;
      padding: 20px;

      .avatar_title {
        display: none;
      }
    }
  }
}

@primary-color: #0A66C2;@border-radius-base: 5px;